const baseURL =
    "https://apivercel-blush.vercel.app/api/answerQuestionWithCondition"

const mainURL = "https://apivercel-blush.vercel.app/api/answerQuestion"

const generateURL = (objectQuerys, baseURL) => {
    let url

    const querys = Object.keys(objectQuerys)
        .map((key) => `${key}=${encodeURIComponent(objectQuerys[key])}`)
        .join("&")

    url = baseURL + "?" + querys

    return url
}
// console.log(querys);
export const fetchGPTS = async (prompt, maxTokens) => {
    const objectQuerys = { prompt, maxTokens }

    const thread_id = localStorage.getItem("thread")

    if (thread_id) {
        objectQuerys.thread_id = thread_id
    }

    const url = generateURL(objectQuerys, mainURL)

    const response = await fetch(url)

    if (response.status === 200) {
        const data = await response.json()

        if (data.thread) {
            localStorage.setItem("thread", data.thread)
        }

        return { message: data.message[0].content[0].text.value }
    } else {
        const message =
            "Ocorreu um erro em nossos sistemas, tente novamente mais tarde!"

        return { message }
    }
}

export const fetchGPTSWithCondition = async (
    prompt,
    condition1,
    condition2,
    maxTokens
) => {
    const objectQuerys = { prompt, condition1, condition2, maxTokens }

    const thread_id = localStorage.getItem("thread")

    if (thread_id) {
        objectQuerys.thread_id = thread_id
    }

    const url = generateURL(objectQuerys, baseURL)

    const response = await fetch(url)

    if (response.status === 200) {
        const data = await response.json()

        if (data.thread) {
            localStorage.setItem("thread", data.thread)
        }

        return { message: data.message[0].content[0].text.value }
    } else {
        const message =
            "Ocorreu um erro em nossos sistemas, tente novamente mais tarde!"

        return { message }
    }
}

export async function sendMail(obj) {
    const res = await fetch("https://apivercel-blush.vercel.app/api/mail", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
    })

    const data = await res.json()
    console.log(data)
    return data
    // return "ola"
}

export const fetchIp = async () => {
    try {
        const response = await fetch("https://api.ipify.org?format=json")
        const data = await response.json()
        return data.ip
    } catch (error) {
        console.error("Erro ao obter o IP:", error)
    }
}
